import classes from "./classes.module.scss";
import { ReactComponent as Studying } from "assets/images/Icons/studying.svg";
import { ReactComponent as Accepted } from "assets/images/Icons/accepted.svg";
import { ReactComponent as Refused } from "assets/images/Icons/refused.svg";
import { EAnchorStatus } from "common/enums/Anchor/EAnchorStatus";
import { EProjectStatus } from "common/enums/Project/Status/EProjectStatus";
import Typography from "components/elements/Typography";
import I18n from "../I18n";
import { match } from "ts-pattern";
import Tooltip from "components/elements/Tooltip";

type IProps = {
	status: EProjectStatus | EAnchorStatus;
	withDescription?: boolean;
};

export default function StatusIcon(props: IProps) {
	const getIcon = () => {
		return match(props.status)
			.with(EProjectStatus.STUDYING, () => <Studying />)
			.with(EProjectStatus.ACCEPTED, () => <Accepted />)
			.with(EProjectStatus.REFUSED, () => <Refused />)

			.with(EAnchorStatus.QUEUED, () => <Studying />)
			.with(EAnchorStatus.ATTEMPTING, () => <Studying />)
			.with(EAnchorStatus.VERIFIED_ON_CHAIN, () => <Accepted />)
			.with(EAnchorStatus.VERIFYING_ON_CHAIN, () => <Studying />)
			.with(EAnchorStatus.ABANDONED, () => <Refused />)

			.exhaustive();
	};

	const getStatusTranslation = () => {
		return match(props.status)
			.with(EProjectStatus.STUDYING, () => I18n.trslt(I18n.asset.pages.my_projects.filters.studying))
			.with(EProjectStatus.ACCEPTED, () => I18n.trslt(I18n.asset.pages.my_projects.filters.accepted))
			.with(EProjectStatus.REFUSED, () => I18n.trslt(I18n.asset.pages.my_projects.filters.refused))

			.with(EAnchorStatus.QUEUED, () => I18n.trslt(I18n.asset.pages.my_projects.filters.not_anchored))
			.with(EAnchorStatus.ATTEMPTING, () => I18n.trslt(I18n.asset.pages.my_projects.filters.not_anchored))
			.with(EAnchorStatus.VERIFIED_ON_CHAIN, () => I18n.trslt(I18n.asset.pages.my_projects.filters.anchored))
			.with(EAnchorStatus.VERIFYING_ON_CHAIN, () => I18n.trslt(I18n.asset.pages.my_projects.filters.not_anchored))
			.with(EAnchorStatus.ABANDONED, () => I18n.trslt(I18n.asset.pages.my_projects.filters.anchor_failed))

			.exhaustive();
	};

	return (
		<div className={classes["root"]}>
			{!props.withDescription && <Tooltip title={getStatusTranslation()}>{getIcon()}</Tooltip>}
			{props.withDescription && getIcon()}
			{props.withDescription && <Typography>{getStatusTranslation()}</Typography>}
		</div>
	);
}
