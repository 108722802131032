import ProjectResponseResource from "common/resources/Project/ProjectResponseResource";
import classes from "./classes.module.scss";
import classNames from "classnames";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { container } from "tsyringe";
import FileService from "services/FileService";
import Button, { EButtonColor, EButtonVariant } from "components/elements/Button";
import LazyImage from "components/elements/LazyImage";
import DefaultAvatar from "assets/images/default-avatar.svg";
import Typography from "components/elements/Typography";
import I18n from "components/materials/I18n";
import { format } from "date-fns";
import I18nStore from "components/materials/I18n/I18nStore";
import { match } from "ts-pattern";
import StatusIcon from "components/materials/StatusIcon";
import { EProjectStatus } from "common/enums/Project/Status/EProjectStatus";
import Social from "components/materials/SocialButton";
import { useNavigate } from "react-router-dom";
import ModuleConfig from "configs/ModuleConfig";
import { useEffect, useState } from "react";
import { EAnchorStatus } from "common/enums/Anchor/EAnchorStatus";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import AnchorProjectModal from "components/materials/AnchorProjectModal";
import DownloadProofButton from "components/materials/DownloadProofButton";
import PosterImage from "components/materials/PosterImage";
import ProjectService from "services/ProjectService";

type IProps = {
	isOpen: boolean;
	onClose: () => void;
	onChangeGeneratedPosterImage: () => void;
	canChangeGeneratedPosterImage: boolean;
	project: ProjectResponseResource | null;
	onProjectChange: () => void;
	downloadProof: (id: string, title: string) => void;
};

const fileService = container.resolve(FileService);
const modules = container.resolve(ModuleConfig).get().modules;

function formatDate(date: Date): string {
	return format(date, "yyyy-MM-dd");
}

export default function Drawer(props: IProps) {
	const navigate = useNavigate();

	const [isModalOpen, setIsModalOpen] = useState(false);

	const hasSocials = () => {
		return props.project?.x || props.project?.discord || props.project?.instagram || props.project?.linkedin;
	};

	const getPosterUrl = async (project: ProjectResponseResource) => {
		return project.poster?.file ? fileService.getFileUrl("POSTER", project.poster.file.id) : null;
	};

	const getAvatar = () => {
		const fileUrl = props.project?.author?.avatar?.url;
		if (!fileUrl) {
			console.error("No avatar url found");
			return null;
		}
		return fileUrl;
	};

	const publicationDate = () => {
		const createdAt = props.project?.createdAt;
		if (!createdAt) return "";

		const locale = match(I18nStore.getInstance().lang)
			.with("fr", () => "fr-FR")
			.with("en", () => "en-US")
			.exhaustive();

		return new Date(formatDate(createdAt)).toLocaleDateString(locale, { day: "numeric", month: "long", year: "numeric" });
	};

	const getPitch = () => {
		return match(I18nStore.getInstance().lang)
			.with("fr", () => props.project?.pitch)
			.with("en", () => props.project?.pitch_en)
			.exhaustive();
	};

	const toggleModal = () => {
		setIsModalOpen(!isModalOpen);
	};

	const navigateToEdit = () => {
		if (props.project === null) return;
		navigate(modules.pages.EditProject.props.path.replace(":id", props.project.id));
		props.onClose();
	};

	const downloadScenario = () => {
		const folder = "SCENARIO";
		const fileId = props.project?.scenario?.file?.id;
		if (!fileId) {
			console.error("No file id found for scenario");
			return;
		}
		const url = fileService.getFileUrl(folder, fileId);
		window.open(url, "_blank");
	};

	const downloadCv = () => {
		const folder = "CV";
		const fileId = props.project?.cv?.id;
		if (!fileId) {
			console.error("No file id found for cv");
			return;
		}
		const url = fileService.getFileUrl(folder, fileId);
		window.open(url, "_blank");
	};

	const [isSmallScreen, setIsSmallScreen] = useState(window.outerWidth <= 1440); // Assuming $screen-lg is 1024px

	useEffect(() => {
		const handleResize = () => {
			setIsSmallScreen(window.innerWidth <= 1440);
		};
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<div className={classes["root"]}>
			<div
				className={classNames(classes["drawer"], {
					[classes["open"]!]: props.isOpen,
				})}>
				<div className={classes["close-container"]}>
					<XMarkIcon className={classes["close-icon"]} onClick={props.onClose} />
				</div>

				<div className={classes["header"]}>
					<div className={classes["poster-container"]}>
						{props.isOpen && <PosterImage project={props.project} getPosterUrl={getPosterUrl} />}
						{props.canChangeGeneratedPosterImage && (
							<Button variant={EButtonVariant.TEXT} color={EButtonColor.PRIMARY} onClick={props.onChangeGeneratedPosterImage}>
								<ArrowPathIcon />
								<Typography type="p" size="large" weight="medium">
									{I18n.trslt(I18n.asset.pages.admin_dashboard.drawer.poster.regenerate)}
								</Typography>
							</Button>
						)}
					</div>

					<div className={classes["info-container"]}>
						<div className={classes["author-container"]}>
							<Typography type="p" size="medium" weight="bold">
								{I18n.trslt(I18n.asset.pages.admin_dashboard.drawer.author)}
							</Typography>

							<div className={classes["author"]}>
								{props.isOpen && <LazyImage src={getAvatar() ?? DefaultAvatar} alt="Avatar" className={classes["avatar"]} />}
								<div className={classes["user"]}>
									<Typography type="p" size="small" weight="bold">
										{props.project?.author.firstName} {props.project?.author.lastName}
									</Typography>
									<Typography type="p" size="small" weight="bold">
										{props.project?.author.email}
									</Typography>
									<Typography type="p" size="small" weight="bold">
										{props.project?.author.phoneNumber}
									</Typography>
								</div>
							</div>
						</div>

						<div className={classes["info"]}>
							<Typography type="p" size="medium" weight="bold">
								{I18n.trslt(I18n.asset.pages.admin_dashboard.drawer.infos.title)}
							</Typography>

							<Typography type="p" size="small" weight="regular">
								{I18n.trslt(I18n.asset.pages.admin_dashboard.drawer.infos.publication_date)} {publicationDate()}
							</Typography>

							<Typography type="p" size="small" weight="regular">
								{I18n.trslt(I18n.asset.pages.admin_dashboard.drawer.infos.genre)}{" "}
								{props.project && I18n.trslt(I18n.asset.enums.EProjectThemes[props.project.theme])}
							</Typography>

							<Typography type="p" size="small" weight="regular">
								{I18n.trslt(I18n.asset.pages.admin_dashboard.drawer.infos.format)}{" "}
								{props.project && I18n.trslt(I18n.asset.enums.EProjectFormats[props.project.format])}
							</Typography>

							{props.project && (
								<Typography type="p" size="small" weight="regular">
									{I18n.trslt(I18n.asset.pages.admin_dashboard.drawer.infos.reason)}{" "}
									{props.project.purpose.length > 0
										? props.project.purpose.map(({ purpose }) => I18n.trslt(I18n.asset.enums.EProjectPurposes[purpose])).join(" — ")
										: I18n.trslt(I18n.asset.enums.EProjectReasons[props.project.reason])}
								</Typography>
							)}
							{props.project && (
								<div className={classes["status"]}>
									<StatusIcon
										status={
											ProjectService.isPurposeOnlyAnchor(props.project)
												? props.project.scenario?.anchor?.status || EAnchorStatus.QUEUED
												: props.project.status || EProjectStatus.STUDYING
										}
										withDescription
									/>
									{ProjectService.isAccepted(props.project) && (
										<Typography type="p" size="xsmall" weight="medium" className={classes["status-description"]}>
											{I18n.trslt(I18n.asset.pages.admin_dashboard.drawer.infos.accepted_nota_bene)}
										</Typography>
									)}
								</div>
							)}
						</div>
					</div>
				</div>

				<div className={classes["body"]}>
					<Typography type="h1" weight="bold">
						{props.project?.title}
					</Typography>
					<Typography type="p" size="medium" weight="regular">
						{getPitch()}
					</Typography>
				</div>
				<div className={classes["social-title"]}>
					{hasSocials() && props.project && <Typography>{I18n.trslt(I18n.asset.pages.project.follow_us)}</Typography>}
					<div className={classes["social-download"]}>
						{hasSocials() && props.project && <Social project={props.project} />}
						{props.project?.scenario?.anchor ? (
							<Button startIcon={<PencilSquareIcon />} color={EButtonColor.PRIMARY} variant={EButtonVariant.OUTLINED} onClick={navigateToEdit}>
								<Typography type="span" size="medium" weight="regular">
									{I18n.trslt(I18n.asset.pages.my_projects.drawer.button.modify_socials)}
								</Typography>
							</Button>
						) : (
							<>
								{props.project?.cv && (
									<Button variant={EButtonVariant.CONTAINED} color={EButtonColor.NEUTRAL} onClick={downloadCv} className={classes["button"]}>
										<Typography className={classes["button-text"]}>{I18n.trslt(I18n.asset.pages.admin_dashboard.drawer.buttons.cv)}</Typography>
									</Button>
								)}
								{props.project?.scenario?.file?.name && (
									<Button variant={EButtonVariant.CONTAINED} color={EButtonColor.NEUTRAL} onClick={downloadScenario} className={classes["button"]}>
										<Typography className={classes["button-text"]}>{I18n.trslt(I18n.asset.pages.admin_dashboard.drawer.buttons.scenario)}</Typography>
									</Button>
								)}
							</>
						)}
					</div>
				</div>

				<div className={classes["button-container"]}>
					{!props.project?.scenario?.anchor ? (
						<>
							<Button color={EButtonColor.PRIMARY} variant={EButtonVariant.CONTAINED} onClick={toggleModal} fullwidth={isSmallScreen}>
								<Typography type="span" size="medium" weight="regular">
									{I18n.trslt(I18n.asset.pages.my_projects.drawer.button.anchor)}
								</Typography>
							</Button>
							<Button color={EButtonColor.PRIMARY} variant={EButtonVariant.OUTLINED} onClick={navigateToEdit} fullwidth={isSmallScreen}>
								<Typography type="span" size="medium" weight="regular">
									{I18n.trslt(I18n.asset.pages.my_projects.drawer.button.modify)}
								</Typography>
							</Button>
						</>
					) : (
						props.project?.scenario?.anchor?.status === EAnchorStatus.VERIFIED_ON_CHAIN && <DownloadProofButton project={props.project} isSmallScreen={isSmallScreen} />
					)}
				</div>
			</div>

			{props.isOpen && <div className={classes["overlay"]} onClick={props.onClose} />}
			{props.project && <AnchorProjectModal project={props.project} isOpen={isModalOpen} onClose={toggleModal} onAnchorProject={props.onProjectChange} />}
		</div>
	);
}
