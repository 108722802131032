import Typography from "components/elements/Typography";
import I18n from "components/materials/I18n";
import classes from "./classes.module.scss";
import Row from "./Row";
import ProjectResponseResource from "common/resources/Project/ProjectResponseResource";

export type IProps = {
	projectList: ProjectResponseResource[];
	onProjectStatusChange: () => void;
	onProjectArchiveChange: () => void;
	onRowClick: (project: ProjectResponseResource) => void;
	toogleModal: (project: ProjectResponseResource) => void;
};

export default function Table(props: IProps) {
	return (
		<table className={classes["root"]}>
			<thead className={classes["thead"]}>
				<tr>
					<th className={classes["th"]}>
						<Typography type="p" weight="bold" size="medium">
							{I18n.trslt(I18n.asset.pages.admin_dashboard.table.headers.project_name)}
						</Typography>
					</th>
					<th className={classes["th"]}>
						<Typography type="p" weight="bold" size="medium">
							{I18n.trslt(I18n.asset.pages.admin_dashboard.table.headers.author)}
						</Typography>
					</th>
					<th className={classes["th"]}>
						<Typography type="p" weight="bold" size="medium">
							{I18n.trslt(I18n.asset.pages.admin_dashboard.table.headers.status)}
						</Typography>
					</th>
					<th className={classes["th"]}>
						<Typography type="p" weight="bold" size="medium">
							{I18n.trslt(I18n.asset.pages.admin_dashboard.table.headers.date)}
						</Typography>
					</th>
				</tr>
			</thead>

			<tbody className={classes["tbody"]}>
				{props.projectList.map((project) => (
					<Row
						key={project.id}
						project={project}
						onRowClick={props.onRowClick}
						onProjectStatusChange={props.onProjectStatusChange}
						onProjectArchiveChange={props.onProjectArchiveChange}
						toogleModal={props.toogleModal}
					/>
				))}
			</tbody>
		</table>
	);
}
