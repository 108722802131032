import classes from "./classes.module.scss";
import Typography from "components/elements/Typography";
import I18n from "../../I18n";
import { XMarkIcon } from "@heroicons/react/20/solid";
import Button, { EButtonColor, EButtonVariant } from "components/elements/Button";
import { Link } from "react-router-dom";
import { container } from "tsyringe";
import ModuleConfig from "configs/ModuleConfig";

const modules = container.resolve(ModuleConfig).get().modules;

type IProps = {
	onClose: () => void;
};

export default function ComingSoon(props: IProps) {
	return (
		<div className={classes["root"]}>
			<div className={classes["content"]}>
				<div className={classes["title"]}>
					<Typography type="span" size="medium" weight="bold" color="white">
						{I18n.trslt(I18n.asset.component.header.coming_soon.title)}
					</Typography>
				</div>
				<div className={classes["message"]}>
					<Typography type="span" size="medium" weight="regular" color="white" htmlContent={I18n.trslt(I18n.asset.component.header.coming_soon.message)}></Typography>
				</div>
				<div className={classes["message"]}>
					<Typography type="span" size="medium" weight="regular" color="white" htmlContent={I18n.trslt(I18n.asset.component.header.coming_soon.submessage)}></Typography>
				</div>
				<Link to={modules.pages.PrixAliceGuy.props.path} className={classes["link"]}>
					<Button variant={EButtonVariant.CONTAINED} color={EButtonColor.PRIMARY}>
						{I18n.trslt(I18n.asset.component.header.coming_soon.button)}
					</Button>
				</Link>
			</div>
			<div className={classes["close-container"]}>
				<XMarkIcon className={classes["close-icon"]} onClick={props.onClose} color="white" />
			</div>
		</div>
	);
}
