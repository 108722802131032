import I18n from "components/materials/I18n";
import PageTemplate from "components/PageTemplate";
import classes from "./classes.module.scss";

import Header from "./Header";
import Button, { EButtonColor, EButtonVariant } from "components/elements/Button";
import Typography from "components/elements/Typography";
import Section1 from "assets/images/prix-alice-section-1.jpeg";
import Section2 from "assets/images/prix-alice-section-2.jpeg";
import LazyImage from "components/elements/LazyImage";
import FrameDesign from "assets/images/frame-design.png";
import { container } from "tsyringe";
import ModuleConfig from "configs/ModuleConfig";
import { Link } from "react-router-dom";

const modules = container.resolve(ModuleConfig).get().modules;
type IProps = {};

export default function PrixAliceGuy(_: IProps) {
	return (
		<PageTemplate tabTitle={I18n.trslt(I18n.asset.pages.prix_alice_guy.page_title)} mainSectionClassName={classes["root"]}>
			<Header />
			<img src={FrameDesign} alt="Frame design" className={classes["frame-image"]} />
			<div className={classes["container"]}>
				<div className={classes["section-1"]}>
					<div className={classes["text"]}>
						<Typography type="h2" weight="bold">
							{I18n.asset.pages.prix_alice_guy.sections.first.title}
						</Typography>
						<div className={classes["description"]}>
							<Typography type="p" weight="regular" size="medium" htmlContent={I18n.asset.pages.prix_alice_guy.sections.first.description}></Typography>
						</div>
						<Link to={modules.pages.PrixAliceGuy.props.votePage} className={classes["link"]} target="_blank">
							<Button variant={EButtonVariant.CONTAINED} color={EButtonColor.PRIMARY}>
								{I18n.trslt(I18n.asset.pages.prix_alice_guy.buttons.vote)}
							</Button>
						</Link>
					</div>
					<LazyImage src={Section1} alt="Poster" className={classes["img"]} />
				</div>

				<div className={classes["section-2"]}>
					<LazyImage src={Section2} alt="Poster" className={classes["img"]} />

					<div className={classes["text"]}>
						<Typography type="h2" weight="bold">
							{I18n.asset.pages.prix_alice_guy.sections.second.title}
						</Typography>
						<ul className={classes["description"]}>
							<li>
								<Typography type="p" weight="regular" size="large">
									{I18n.asset.pages.prix_alice_guy.sections.second.list.first}
								</Typography>
							</li>
							<br />
							<li>
								<Typography type="p" weight="regular" size="large">
									{I18n.asset.pages.prix_alice_guy.sections.second.list.second}
								</Typography>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</PageTemplate>
	);
}
