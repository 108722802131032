import PrixAlice from "assets/images/prix-alice-guy.png";
import Button, { EButtonColor, EButtonVariant } from "components/elements/Button";
import Typography from "components/elements/Typography";
import I18n from "components/materials/I18n";
import classes from "./classes.module.scss";
import { Link } from "react-router-dom";
import { container } from "tsyringe";
import ModuleConfig from "configs/ModuleConfig";

const modules = container.resolve(ModuleConfig).get().modules;
type IProps = {};

export default function Header(_: IProps) {
	return (
		<div className={classes["root"]}>
			<div className={classes["content-container"]}>
				<div className={classes["header"]}>
					<div className={classes["infos"]}>
						<Typography type="p" weight="medium" size="small" className={classes["info"]}>
							{I18n.trslt(I18n.asset.pages.prix_alice_guy.header.publication)}
						</Typography>
						<Typography type="p" weight="medium" size="small" className={classes["info"]}>
							{I18n.trslt(I18n.asset.pages.prix_alice_guy.header.genre)}
						</Typography>
						<Typography type="p" weight="medium" size="small" className={classes["info"]}>
							{I18n.trslt(I18n.asset.pages.prix_alice_guy.header.format)}
						</Typography>
					</div>
				</div>

				<div className={classes["content"]}>
					<div className={classes["title-container"]}>
						<Typography type="h1" weight="bold">
							{I18n.trslt(I18n.asset.pages.prix_alice_guy.content.title)}
						</Typography>
					</div>
					<Typography type="p" weight="regular" size="medium">
						{I18n.trslt(I18n.asset.pages.prix_alice_guy.content.description)}
					</Typography>
					<Typography type="p" weight="bold" size="medium">
						{I18n.trslt(I18n.asset.pages.prix_alice_guy.content.sub_description)}
					</Typography>
					<Link to={modules.pages.PrixAliceGuy.props.discoverPage} className={classes["link"]} target="_blank">
						<Button variant={EButtonVariant.CONTAINED} color={EButtonColor.PRIMARY}>
							{I18n.trslt(I18n.asset.pages.prix_alice_guy.buttons.discover)}
						</Button>
					</Link>
				</div>
			</div>
			<div className={classes["poster-container"]}>
				<img src={PrixAlice} alt="Poster" className={classes["poster"]} />
			</div>
		</div>
	);
}
