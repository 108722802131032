import React from "react";
import { MessageCircle } from "lucide-react";
import classes from "./classes.module.scss";
import I18n from "components/materials/I18n";

export function EmptyComments() {
	return (
		<div className={classes["root"]}>
			<MessageCircle className="mx-auto h-12 w-12 text-gray-400 mb-4" />
			<h3 className="text-lg font-medium text-gray-900 mb-2">{I18n.trslt(I18n.asset.pages.post.comment.empty)}</h3>
			<p className="text-gray-500">{I18n.trslt(I18n.asset.pages.post.comment.share)}</p>
		</div>
	);
}
