import PageTemplate from "components/PageTemplate";
import classes from "./classes.module.scss";
import I18n from "components/materials/I18n";
import Typography from "components/elements/Typography";
import Table from "./Table";
import AdminProjectService from "services/admin/ProjectService";
import ProjectService from "services/ProjectService";
import { container } from "tsyringe";
import ProjectResponseResource from "common/resources/Project/ProjectResponseResource";
import { useCallback, useEffect, useState } from "react";
import Drawer from "./Drawer";
import { EProjectStatus } from "common/enums/Project/Status/EProjectStatus";
import Button, { EButtonVariant } from "components/elements/Button";
import Pagination from "components/elements/Pagination";
import { match } from "ts-pattern";
import AnchorProjectModal from "components/materials/AnchorProjectModal";

const adminProjectService = container.resolve(AdminProjectService);
const projectService = container.resolve(ProjectService);
const projectsPerPage = 20;

function clamp(value: number, min: number, max: number): number {
	return Math.min(Math.max(value, min), max);
}

function clampPage(page: number, totalPages: number): number {
	return clamp(page, 1, Math.max(1, totalPages));
}

export default function AdminDashboard() {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [selectedProject, setSelectedProject] = useState<ProjectResponseResource | null>(null);
	const [canChangeSelectedProjectPosterImage, setCanChangeSelectedProjectPosterImage] = useState<boolean>(false);
	const [projectList, setProjectList] = useState<ProjectResponseResource[]>([]);
	const [projectCount, setProjectCount] = useState(0);
	const [tabSelected, setTabSelected] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const getProjectList = useCallback(async (currentPage: number, tabSelected: number) => {
		let projectList: ProjectResponseResource[] = [];
		const pagination = { skip: (currentPage - 1) * projectsPerPage, take: projectsPerPage };
		switch (tabSelected) {
			case 0:
				projectList = await adminProjectService.getProjects(pagination);
				break;
			case 1:
				projectList = await adminProjectService
					.getProjectByStatus(EProjectStatus.STUDYING, pagination)
					.then((projects) => projects.filter((project) => !ProjectService.isPurposeOnlyAnchor(project)));
				break;
			case 2:
				projectList = await adminProjectService
					.getProjectByStatus(EProjectStatus.ACCEPTED, pagination)
					.then((projects) => projects.filter((project) => !ProjectService.isPurposeOnlyAnchor(project)));
				break;
			case 3:
				projectList = await adminProjectService
					.getProjectByStatus(EProjectStatus.REFUSED, pagination)
					.then((projects) => projects.filter((project) => !ProjectService.isPurposeOnlyAnchor(project)));
				break;
			case 4:
				projectList = await adminProjectService.getArchivedProjects(pagination);
				break;
		}
		setProjectList(projectList);
		return projectList;
	}, []);

	const onSelectProject = useCallback(async (project: ProjectResponseResource) => {
		setSelectedProject(project);
		setCanChangeSelectedProjectPosterImage(false);

		if (project.poster) {
			await adminProjectService.canDiscardGeneratedPosterImage(project.id).then(({ canDiscard }) => {
				setCanChangeSelectedProjectPosterImage(canDiscard);
			});
		}

		return project;
	}, []);

	const updateProjectList = useCallback(async (project: ProjectResponseResource) => {
		setProjectList((prev) => prev.map((p) => (p.id === project.id ? project : p)));
		return project;
	}, []);

	const updateProject = useCallback(
		async (project: ProjectResponseResource) => {
			projectService
				.getProjectById(project.id)
				.then((project) => updateProjectList(project))
				.then((project) => onSelectProject(project));
		},
		[onSelectProject, updateProjectList],
	);

	const countProjects = useCallback(async (tabSelected: number) => {
		const total = await match(tabSelected)
			.with(0, () => adminProjectService.countProjects())
			.with(1, () => adminProjectService.countProjectsByStatus(EProjectStatus.STUDYING))
			.with(2, () => adminProjectService.countProjectsByStatus(EProjectStatus.ACCEPTED))
			.with(3, () => adminProjectService.countProjectsByStatus(EProjectStatus.REFUSED))
			.with(4, () => adminProjectService.countArchivedProjects())
			.otherwise(() => null);

		if (total === null) {
			console.error("Total project count is not found");
			return;
		}

		setProjectCount(total);
	}, []);

	const getTotalPages = useCallback(() => Math.max(1, Math.ceil(projectCount / projectsPerPage)), [projectCount]);

	const isFilterSelected = (index: number) => tabSelected === index;

	const onRowClick = useCallback(
		async (project: ProjectResponseResource) => {
			onSelectProject(project).then(() => {
				setIsDrawerOpen(true);
				updateProject(project);
			});
		},
		[onSelectProject, updateProject],
	);

	const onChangeGeneratedPosterImage = useCallback(async () => {
		if (!selectedProject) return;

		setCanChangeSelectedProjectPosterImage(false);

		adminProjectService.changeGeneratedPosterImage(selectedProject.id).then((change) => {
			if ("next" in change) updateProject(selectedProject);
		});
	}, [selectedProject, updateProject]);

	const onDrawerClose = useCallback(() => {
		setSelectedProject(null);
		setIsDrawerOpen(false);
	}, []);

	const onChangePage = useCallback(
		async (currentPage: number) => {
			setCurrentPage(clampPage(currentPage, getTotalPages()));
			await getProjectList(currentPage, tabSelected);
		},
		[getProjectList, getTotalPages, tabSelected],
	);

	const onProjectChange = useCallback(async () => {
		await countProjects(tabSelected);
		const updatedProjectList = await getProjectList(currentPage, tabSelected);

		if (updatedProjectList.length === 0 && currentPage > 1) return onChangePage(currentPage - 1);

		const updatedProject = updatedProjectList.find((p) => p.id === selectedProject?.id);
		if (!updatedProject) {
			console.warn("Selected Project not found in the list, maybe because drawer is not open");
			return;
		}
		setSelectedProject(updatedProject);
	}, [countProjects, currentPage, getProjectList, onChangePage, selectedProject?.id, tabSelected]);

	const onTabClick = useCallback(
		async (index: number) => {
			setTabSelected(index);
			setCurrentPage(1);
			await countProjects(index);
			await getProjectList(1, index);
		},
		[countProjects, getProjectList],
	);

	const toggleModal = (project: ProjectResponseResource) => {
		setSelectedProject(project);
		setIsModalOpen(!isModalOpen);
	};

	useEffect(() => {
		const initialTab = 0;
		const initialPage = 1;

		countProjects(initialTab);
		getProjectList(initialPage, initialTab);
	}, [countProjects, getProjectList]);

	return (
		<PageTemplate tabTitle={I18n.trslt(I18n.asset.pages.admin_dashboard.page_title)} mainSectionClassName={classes["root"]}>
			<Typography type="h1" weight="bold" className={classes["title"]}>
				{I18n.trslt(I18n.asset.pages.admin_dashboard.title)}
			</Typography>
			<div className={classes["filters"]}>
				<Button variant={EButtonVariant.SELECTOR} onClick={() => onTabClick(0)} isSelected={isFilterSelected(0)}>
					{I18n.trslt(I18n.asset.pages.admin_dashboard.filters.all_projects)}
				</Button>
				<Button variant={EButtonVariant.SELECTOR} onClick={() => onTabClick(1)} isSelected={isFilterSelected(1)}>
					{I18n.trslt(I18n.asset.pages.admin_dashboard.filters.studying)}
				</Button>
				<Button variant={EButtonVariant.SELECTOR} onClick={() => onTabClick(2)} isSelected={isFilterSelected(2)}>
					{I18n.trslt(I18n.asset.pages.admin_dashboard.filters.accepted)}
				</Button>
				<Button variant={EButtonVariant.SELECTOR} onClick={() => onTabClick(3)} isSelected={isFilterSelected(3)}>
					{I18n.trslt(I18n.asset.pages.admin_dashboard.filters.refused)}
				</Button>
				<Button variant={EButtonVariant.SELECTOR} onClick={() => onTabClick(4)} isSelected={isFilterSelected(4)}>
					{I18n.trslt(I18n.asset.pages.admin_dashboard.filters.archived)}
				</Button>
			</div>
			<Table projectList={projectList} onRowClick={onRowClick} onProjectStatusChange={onProjectChange} onProjectArchiveChange={onProjectChange} toogleModal={toggleModal} />
			{selectedProject && (
				<Drawer
					isOpen={isDrawerOpen}
					onClose={onDrawerClose}
					onChangeGeneratedPosterImage={onChangeGeneratedPosterImage}
					canChangeGeneratedPosterImage={canChangeSelectedProjectPosterImage}
					project={selectedProject}
					onProjectChange={onProjectChange}
					toogleModal={toggleModal}
				/>
			)}
			<div className={classes["pagination"]}>
				<Pagination lastPage={getTotalPages()} currentPage={currentPage} onChangePage={onChangePage} />
			</div>
			{selectedProject && (
				<AnchorProjectModal project={selectedProject} isOpen={isModalOpen} onClose={() => toggleModal(selectedProject)} onAnchorProject={onProjectChange} />
			)}
		</PageTemplate>
	);
}
