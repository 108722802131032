import classNames from "classnames";
import React, { ReactNode } from "react";
import classes from "./classes.module.scss";

type TypoColors = "primary" | "secondary" | "error" | "warning" | "success" | "neutral" | "white" | "inherit";
type TypoType = "h1" | "h2" | "h3" | "h4" | "h5" | "p" | "span";
type TypoSizes = "xlarge" | "large" | "medium" | "small" | "xsmall";
type TypoWeights = "bold" | "medium" | "regular";
type TypoFontFamily = "primary" | "secondary";

export type IProps = {
	children?: ReactNode; // For ReactNode content
	htmlContent?: string; // For raw HTML content
	type?: TypoType;
	size?: TypoSizes;
	weight?: TypoWeights;
	color?: TypoColors;
	fontFamily?: TypoFontFamily;
	className?: string;
	hide?: boolean;
};

export default class Typography extends React.Component<IProps> {
	constructor(props: IProps) {
		super(props);
		this.state = {};
	}

	public override render(): React.ReactNode {
		const type: TypoType = this.props.type ?? "p";
		const size: TypoSizes = this.props.size ?? "medium";
		const weight: TypoWeights = this.props.weight ?? "regular";
		const color: TypoColors = this.props.color ?? "neutral";
		const fontFamily: TypoFontFamily = this.props.fontFamily ?? "primary";
		const hide: string | undefined = this.props.hide ? classes["hide"] : "";

		// Conditionally render children or raw HTML
		const content = this.props.htmlContent
			? { dangerouslySetInnerHTML: { __html: this.props.htmlContent } }
			: { children: this.props.children };

		return React.createElement(
			type,
			{
				className: classNames(
					classes["root"],
					classes[type],
					classes[`size-${size}`],
					classes[`font-${fontFamily}`],
					classes[`weight-${weight}`],
					classes[`color-${color}`],
					hide,
					this.props.className
				),
				...content, // Spread the content props
			}
		);
	}
}
