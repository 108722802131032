import ProjectResponseResource from "common/resources/Project/ProjectResponseResource";
import classes from "./classes.module.scss";
import LazyImage from "components/elements/LazyImage";
import Typography from "components/elements/Typography";
import AnimatedLoader from "components/elements/AnimatedLoader";
import I18n from "components/materials/I18n";
import { match } from "ts-pattern";
import { EPosterStatus } from "common/enums/Project/Poster/EPosterStatus";
import { useCallback, useEffect, useState } from "react";
// import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";

type IProps = {
	project: ProjectResponseResource | null;
	getPosterUrl: (project: ProjectResponseResource) => Promise<string | null>;
};

export default function Drawer(props: IProps) {
	const [posterUrl, setPosterUrl] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const isPosterCompleted = useCallback(() => {
		return props.project?.poster?.status === EPosterStatus.COMPLETED;
	}, [props]);

	const getPosterMessage = () => {
		const status = props.project?.poster?.status;
		return match(status)
			.with(EPosterStatus.PENDING, () => I18n.trslt(I18n.asset.pages.admin_dashboard.drawer.poster.pending))
			.with(EPosterStatus.FAILED, () => I18n.trslt(I18n.asset.pages.admin_dashboard.drawer.poster.failed))
			.otherwise(() => I18n.trslt(I18n.asset.pages.admin_dashboard.drawer.poster.pending));
	};

	const getPosterUrl = useCallback(async () => {
		if (!props.project || !isPosterCompleted()) return;

		setIsLoading(true);
		props
			.getPosterUrl(props.project)
			.then(setPosterUrl)
			.finally(() => setIsLoading(false));
	}, [props, isPosterCompleted]);

	useEffect(() => {
		getPosterUrl();
	}, [getPosterUrl, props]);

	return (
		<div className={classes["poster-container"]}>
			{isLoading ? (
				<div className={classes["loading-poster"]}>
					<AnimatedLoader />
				</div>
			) : isPosterCompleted() ? (
				<LazyImage src={posterUrl} alt="Poster" className={classes["poster"]} />
			) : (
				<div className={classes["missing-poster"]}>
					<Typography color="secondary">{getPosterMessage()}</Typography>
					<AnimatedLoader />
				</div>
			)}
		</div>
	);
}
