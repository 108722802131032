import Button, { EButtonColor, EButtonSize, EButtonVariant } from "components/elements/Button";
import classes from "./classes.module.scss";
import Typography from "components/elements/Typography";
import DefaultAvatar from "assets/images/default-avatar.svg";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import I18n from "components/materials/I18n";
import ModuleConfig from "configs/ModuleConfig";
import { container } from "tsyringe";
import UserAccountInfoResponseResource from "common/resources/User/UserAccountInfoResponseResource";
import AuthService from "services/AuthService";
import classNames from "classnames";
import HasRules from "components/materials/HasRules";

const modules = container.resolve(ModuleConfig).get().modules;
const authService = container.resolve(AuthService);

type IProps = {
	user: UserAccountInfoResponseResource;
	className?: string;
};

export default function ConnectedHeader(props: IProps) {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const menuRef = useRef<HTMLDivElement>(null);

	const toggleMenu = useCallback(() => {
		setIsMenuOpen((prev) => !prev);
	}, []);

	const handleClickOutside = useCallback(
		(event: MouseEvent) => {
			if (!isMenuOpen) return;
			if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
				setIsMenuOpen(false);
			}
		},
		[isMenuOpen],
	);

	const logout = useCallback(() => {
		authService.logout();
	}, []);

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [handleClickOutside]);

	return (
		<div className={classNames(classes["root"], props.className)}>
			<Link to={modules.pages.SubmitProject.props.path} className={classes["link"]}>
				<Button variant={EButtonVariant.CONTAINED} color={EButtonColor.PRIMARY} size={EButtonSize.MEDIUM}>
					<Typography type="span" size="medium" weight="medium">
						{I18n.trslt(I18n.asset.component.header.submit_project)}
					</Typography>
				</Button>
			</Link>

			<div className={classes["menu-container"]} onClick={toggleMenu}>
				<div className={classes["avatar-container"]}>
					<img src={props.user.avatar?.url ?? DefaultAvatar} alt="Avatar" className={classes["avatar"]} />
				</div>

				<ChevronDownIcon className={classes["icon"]} />

				{isMenuOpen && (
					<div className={classes["menu"]} ref={menuRef}>
						<Link to={modules.pages.Profile.props.path} className={classes["link"]}>
							<Typography type="span" size="medium" weight="medium" className={classes["whitespace-wrap"]}>
								{I18n.trslt(I18n.asset.component.header.personnal_informations)}
							</Typography>
						</Link>

						<Link to={modules.pages.MyProjects.props.path} className={classes["link"]}>
							<Typography type="span" size="medium" weight="medium" className={classes["whitespace-wrap"]}>
								{I18n.trslt(I18n.asset.component.header.my_projects)}
							</Typography>
						</Link>

						<HasRules requiredRules={{ AND: { access__admin_dashboard: true } }}>
							<Link to={modules.pages.AdminDashboard.props.path} className={classes["link"]}>
								<Typography type="span" size="medium" weight="medium" className={classes["whitespace-wrap"]}>
									{I18n.trslt(I18n.asset.component.header.all_projects)}
								</Typography>
							</Link>
						</HasRules>

						<div onClick={logout}>
							<Typography type="span" size="medium" weight="medium" className={classes["whitespace-wrap"]}>
								{I18n.trslt(I18n.asset.component.header.logout)}
							</Typography>
						</div>

					</div>
				)}
			</div>
		</div>
	);
}
