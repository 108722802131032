import { CommentCard } from "./CommentCard";

import CommentResponseResource from "common/resources/Comment/CommentResponseResource";
import PostResponseResource from "common/resources/Post/PostResponseResource";
import { EmptyComments } from "./EmptyComments/EmptyComments";

interface CommentListProps {
	post: PostResponseResource;
	comments: CommentResponseResource[];
	onDelete: (commentId: string) => Promise<void>;
}

export function CommentList({ post, comments, onDelete }: CommentListProps) {
	if (comments.length === 0) {
		return <EmptyComments />;
	}

	return (
		<div className="space-y-6">
			{comments.map((comment) => (
				<CommentCard key={comment.id} post={post} comment={comment} onDelete={onDelete} />
			))}
		</div>
	);
}
