import React, { useRef, useState } from "react";
import { X } from "lucide-react";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import PostResponseResource from "common/resources/Post/PostResponseResource";
import Button, { EButtonColor, EButtonSize, EButtonVariant } from "components/elements/Button";
import Typography from "components/elements/Typography";
import I18n from "components/materials/I18n";

interface CommentFormProps {
	post: PostResponseResource;
	onSubmit: (text: string, imageUrl?: string) => void;
}

export function CommentForm({ post, onSubmit }: CommentFormProps) {
	const [text, setText] = useState("");
	const [imageUrl, setImageUrl] = useState("");
	const [showImageUrl, setShowImageUrl] = useState(false);
	const [previewImage, setPreviewImage] = useState<string | null>(null);
	const fileInputRef = useRef<HTMLInputElement>(null);

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		if (text.trim()) {
			onSubmit(text, previewImage || imageUrl);
			setText("");
			setImageUrl("");
			setPreviewImage(null);
			setShowImageUrl(false);
		}
	};

	const handleRemoveImage = () => {
		setPreviewImage(null);
		setImageUrl("");
		if (fileInputRef.current) {
			fileInputRef.current.value = "";
		}
	};

	return (
		<form onSubmit={handleSubmit} className="comment-form">
			<h2 className="comment-form__title">{post.subtitle ?? I18n.trslt(I18n.asset.pages.post.comment.default_subtitle)}</h2>
			<textarea
				value={text}
				onChange={(e) => setText(e.target.value)}
				className="comment-form__textarea"
				placeholder={I18n.trslt(I18n.asset.pages.post.comment.placeholder)}
				rows={4}
			/>

			<div className="comment-form__actions">
				<Button type="submit" variant={EButtonVariant.CONTAINED} color={EButtonColor.PRIMARY} size={EButtonSize.MEDIUM} className="comment-form__submit">
					<PaperAirplaneIcon />
					<Typography type="span" size="medium" weight="medium">
						{I18n.trslt(I18n.asset.pages.post.comment.submit)}
					</Typography>
				</Button>
			</div>

			{showImageUrl && <input type="url" value={imageUrl} onChange={(e) => setImageUrl(e.target.value)} placeholder="Entrez l'URL de l'image" className="input mt-md" />}

			{(previewImage || imageUrl) && (
				<div className="comment-form__preview">
					<img src={previewImage || imageUrl} alt="Preview" />
					<button type="button" onClick={handleRemoveImage} className="comment-form__preview-remove">
						<X size={16} />
					</button>
				</div>
			)}
		</form>
	);
}
