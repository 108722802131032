import SubscribeRequestResource from "common/resources/Form/SubscribeRequestResource";
import { ValidationError } from "common/resources/Resource";
import Button, { EButtonColor, EButtonSize, EButtonVariant } from "components/elements/Button";
import Typography from "components/elements/Typography";
import Form from "components/materials/Form";
import Input from "components/materials/Form/InputElement";
import TextArea from "components/materials/Form/TextAreaElement";
import I18n from "components/materials/I18n";
import { useCallback, useState, useRef } from "react";
import FormService from "services/FormService";
import { container } from "tsyringe";
import classes from "./classes.module.scss";
import ModuleConfig from "configs/ModuleConfig";
import { Link, useNavigate } from "react-router-dom";
import SubscribeDesignOne from "assets/images/subscribe-design-1.svg";
import SubscribeDesignTwo from "assets/images/subscribe-design-2.svg";
import SubscribeDesignThree from "assets/images/subscribe-design-3.svg";
import SubscribeDesignFour from "assets/images/subscribe-design-4.svg";
import SubscribeDesignFive from "assets/images/subscribe-design-5.svg";
import SubscribeDesignSix from "assets/images/subscribe-design-6.svg";
import SubscribeDesignSeven from "assets/images/subscribe-design-7.svg";
import SubscribeDesignEight from "assets/images/subscribe-design-8.svg";
import Radio from "components/materials/Form/RadioElement";
import Footer from "components/materials/Footer";
import Header from "components/materials/Header";

const formService = container.resolve(FormService);
const modules = container.resolve(ModuleConfig).get().modules;

export default function Subscribe() {
	const navigate = useNavigate();
	const [errors, setErrors] = useState<ValidationError[]>([]);

	const formRef = useRef<HTMLDivElement>(null);

	const handleScroll = () => {
		formRef.current?.scrollIntoView({ behavior: "smooth" });
	};

	const onSubmit = useCallback(
		(event: React.FormEvent<HTMLFormElement>, formData: { [key: string]: unknown }) => {
			event.preventDefault();
			setErrors([]);

			const subscribeRequest = SubscribeRequestResource.hydrate<SubscribeRequestResource>({
				email: formData["email"] as string,
				message: formData["message"] as string,
				firstName: formData["firstName"] as string,
				lastName: formData["lastName"] as string,
				reason: formData["reason"] as string,
				phone: formData["phone"] as string,
				lang: I18n.getLang(),
			});

			subscribeRequest
				.validateOrReject()
				.then(() => {
					formService.subscribe(subscribeRequest).then(() => {
						alert(I18n.trslt(I18n.asset.pages.subscribe.confirmation));
						navigate(modules.pages.Home.props.path);
					});
				})
				.catch((error) => {
					console.error({ error });
					if (error instanceof Array) {
						setErrors(error);
					}
				});
		},
		[navigate],
	);

	return (
		<div className={classes["root"]}>
			<Header />
			<div className={classes["main"]}>
				<div className={classes["content"]}>
					<div className={classes["header-container"]}>
						<div className={classes["box"]}>
							<Typography type="p" weight="bold" size="xlarge" color="secondary">
								{I18n.trslt(I18n.asset.pages.subscribe.box)}
							</Typography>
						</div>

						<div className={classes["header"]}>
							<Typography type="h1" weight="bold">
								{I18n.trslt(I18n.asset.pages.subscribe.title)}
								<Typography type="span" weight="bold" color="primary" className={classes["secondary-title"]}>
									&nbsp;{I18n.trslt(I18n.asset.pages.subscribe.inside_title)}
								</Typography>
							</Typography>

							<div className={classes["separator"]} />

							<div className={classes["header-content"]}>
								<div className={classes["project-call-container"]}>
									<div className={classes["project-call"]}>
										<div className={classes["project-call-content"]}>
											<Typography type="p" weight="bold" size="xlarge">
												{I18n.trslt(I18n.asset.pages.subscribe.project_call.title)}
											</Typography>
											<Typography type="p" size="medium" weight="regular">
												{I18n.trslt(I18n.asset.pages.subscribe.project_call.description)}
											</Typography>
										</div>
										<Link to={modules.pages.Contact.props.path} className={classes["link"]}>
											<Button variant={EButtonVariant.CONTAINED} color={EButtonColor.PRIMARY} size={EButtonSize.MEDIUM}>
												<Typography type="span" size="medium" weight="medium">
													{I18n.trslt(I18n.asset.pages.featuredProjects.kdanse.action.contact)}
												</Typography>
											</Button>
										</Link>
									</div>
								</div>
								<div className={classes["description"]}>
									<div className={classes["description-box"]}>
										<Typography type="h2" weight="bold">
											{I18n.trslt(I18n.asset.pages.subscribe.description.title)}
										</Typography>
										<Typography type="p" size="medium" weight="regular">
											{I18n.trslt(I18n.asset.pages.subscribe.description.content1)}
										</Typography>
										<Typography type="p" size="medium" weight="regular">
											{I18n.trslt(I18n.asset.pages.subscribe.description.content2)}
										</Typography>
									</div>
									<div className={classes["suscribe-box"]}>
										<Typography type="h2" weight="bold">
											{I18n.trslt(I18n.asset.pages.subscribe.button_box.title)}
										</Typography>
										<Typography type="p" size="medium" weight="regular">
											{I18n.trslt(I18n.asset.pages.subscribe.button_box.content1)}
										</Typography>
										<Typography type="p" size="medium" weight="bold">
											{I18n.trslt(I18n.asset.pages.subscribe.button_box.content2)}
										</Typography>
										<Button variant={EButtonVariant.CONTAINED} color={EButtonColor.PRIMARY} fullwidth onClick={handleScroll}>
											{I18n.trslt(I18n.asset.pages.subscribe.button_box.button)}
										</Button>
									</div>
								</div>
							</div>
						</div>
					</div>

					<Typography type="h2" weight="bold">
						{I18n.trslt(I18n.asset.pages.subscribe.abilities_title)}
					</Typography>

					<div className={classes["design-container"]}>
						<div className={classes["design"]}>
							<img src={SubscribeDesignOne} alt="" className={classes["design-image"]} />

							<Typography type="p" size="medium" weight="regular">
								{I18n.trslt(I18n.asset.pages.subscribe.deposit)}
							</Typography>
						</div>

						<div className={classes["design"]}>
							<img src={SubscribeDesignTwo} alt="" className={classes["design-image"]} />

							<Typography type="p" size="medium" weight="regular">
								{I18n.trslt(I18n.asset.pages.subscribe.federate)}
							</Typography>
						</div>

						<div className={classes["design"]}>
							<img src={SubscribeDesignThree} alt="" className={classes["design-image"]} />

							<Typography type="p" size="medium" weight="regular">
								{I18n.trslt(I18n.asset.pages.subscribe.contribute)}
							</Typography>
						</div>

						<div className={classes["design"]}>
							<img src={SubscribeDesignFour} alt="" className={classes["design-image"]} />

							<Typography type="p" size="medium" weight="regular">
								{I18n.trslt(I18n.asset.pages.subscribe.use_data)}
							</Typography>
						</div>

						<div className={classes["design"]}>
							<img src={SubscribeDesignFive} alt="" className={classes["design-image"]} />

							<Typography type="p" size="medium" weight="regular">
								{I18n.trslt(I18n.asset.pages.subscribe.deposit_content)}
							</Typography>
						</div>

						<div className={classes["design"]}>
							<img src={SubscribeDesignSix} alt="" className={classes["design-image"]} />

							<Typography type="p" size="medium" weight="regular">
								{I18n.trslt(I18n.asset.pages.subscribe.offer)}
							</Typography>
						</div>

						<div className={classes["design"]}>
							<img src={SubscribeDesignSeven} alt="" className={classes["design-image"]} />

							<Typography type="p" size="medium" weight="regular">
								{I18n.trslt(I18n.asset.pages.subscribe.drive)}
							</Typography>
						</div>

						<div className={classes["design"]}>
							<img src={SubscribeDesignEight} alt="" className={classes["design-image"]} />

							<Typography type="p" size="medium" weight="regular">
								{I18n.trslt(I18n.asset.pages.subscribe.shape)}
							</Typography>
						</div>
					</div>
				</div>
			</div>
			<div ref={formRef} className={classes["form"]}>
				<Typography type="h2" weight="bold">
					{I18n.trslt(I18n.asset.pages.subscribe.contact)}
				</Typography>
				<Form onSubmit={onSubmit} errors={errors} className={classes["form-container"]}>
					<div className={classes["input-container"]}>
						<div className={classes["input-row-wrapper"]}>
							<Input name="firstName" label={I18n.trslt(I18n.asset.pages.subscribe.labels.firstname)} required className={classes["input"]} />
							<Input name="lastName" label={I18n.trslt(I18n.asset.pages.subscribe.labels.lastname)} required className={classes["input"]} />
						</div>
						<Input name="address" label={I18n.trslt(I18n.asset.pages.subscribe.labels.address)} required className={classes["input"]} />
						<Input name="phone" label={I18n.trslt(I18n.asset.pages.subscribe.labels.phone)} required className={classes["input"]} />
						<Input placeholder="johndoe@gmail.com" name="email" label={I18n.trslt(I18n.asset.pages.subscribe.labels.email)} required className={classes["input"]} />
						<Typography type="p" size="medium" weight="regular">
							{I18n.trslt(I18n.asset.pages.subscribe.radio.title)}
						</Typography>
						<Radio
							required
							name="reason"
							value={I18n.trslt(I18n.asset.pages.subscribe.radio.options.build)}
							label={I18n.trslt(I18n.asset.pages.subscribe.radio.options.build)}
						/>
						<Radio
							required
							name="reason"
							value={I18n.trslt(I18n.asset.pages.subscribe.radio.options.access)}
							label={I18n.trslt(I18n.asset.pages.subscribe.radio.options.access)}
						/>
						<TextArea
							placeholder="Type your message..."
							name="message"
							label={I18n.trslt(I18n.asset.pages.subscribe.labels.message)}
							className={classes["textarea-container"]}
						/>
					</div>

					<Button variant={EButtonVariant.CONTAINED} color={EButtonColor.PRIMARY} size={EButtonSize.LARGE} type="submit" fullwidth>
						{I18n.trslt(I18n.asset.pages.contact.button)}
					</Button>
				</Form>
			</div>
			<Footer />
		</div>
	);
}
